var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
function SubscriberActionsDropdown(_a) {
    var isDropdownOpen = _a.isDropdownOpen, setIsDropdownOpen = _a.setIsDropdownOpen, selectedAction = _a.selectedAction, selectedProductName = _a.selectedProductName, setSelectedProductName = _a.setSelectedProductName, setSelectedAction = _a.setSelectedAction, setSelectedProduct = _a.setSelectedProduct, setShowProducts = _a.setShowProducts, showProducts = _a.showProducts, contentSuppliers = _a.contentSuppliers, selectService = _a.selectService, activeProducts = _a.activeProducts, activeSubscriber = _a.activeSubscriber, hasServicesForResendEmail = _a.hasServicesForResendEmail, navbarRef = _a.navbarRef;
    var handleSelect = function (action, productId, productName) {
        setSelectedAction(action);
        setSelectedProduct(productId.toString());
        setIsDropdownOpen(false);
        setSelectedProductName(productName);
    };
    return (_jsxs(Navbar, __assign({ expand: "lg", className: "dropdown-to-provision-subscribers" }, { children: [_jsx(Navbar.Toggle, { "aria-controls": "basic-navbar-nav", ref: navbarRef, "data-id": "subscribers-grid" }), _jsx(Navbar.Collapse, __assign({ id: "basic-navbar-nav", className: isDropdownOpen ? 'dropdown-open' : '' }, { children: _jsx(Nav, __assign({ className: "flex-column" }, { children: _jsxs(NavDropdown, __assign({ title: _jsxs("div", __assign({ id: "nav-dropdown-title", style: { display: 'flex', alignItems: 'center', width: '100%', position: 'relative' } }, { children: [_jsx("div", { children: _jsxs("span", __assign({ style: { flexGrow: 1 } }, { children: [" ", selectedAction && selectedProductName ? "".concat(selectedAction, " ").concat(selectedProductName.toUpperCase()) : "Selecione uma ação"] })) }), _jsx("div", { children: isDropdownOpen ? _jsx(KeyboardArrowUpIcon, {}) : _jsx(KeyboardArrowDownIcon, {}) })] })), onToggle: function (isOpen) { return setIsDropdownOpen(isOpen); } }, { children: [['Ativar', 'Suspender', 'Cancelar', 'Reativar'].map(function (action) {
                                var _a;
                                return (_jsxs(NavDropdown.Item, __assign({ onMouseEnter: function () { return setShowProducts(action); }, onMouseLeave: function () { return setShowProducts(null); } }, { children: [_jsx("div", __assign({ onClick: function (e) { e.stopPropagation(); } }, { children: action })), showProducts === action && (_jsx("div", __assign({ className: "product-options" }, { children: (_a = contentSuppliers.find(function (supplier) { return supplier.id === Number(selectService); })) === null || _a === void 0 ? void 0 : _a.content_supplier_products.filter(function (product) { return activeProducts.some(function (p) { return p.id === product.id; }) && product.active; }).map(function (product) { return (product.promotion === 0 && (_jsx("div", __assign({ onClick: function () { return handleSelect(action, product.id, product.name); } }, { children: product.name }), product.id))); }) })))] }), action));
                            }), activeSubscriber && hasServicesForResendEmail(activeSubscriber) && (_jsx(NavDropdown.Item, __assign({ onClick: function () { return handleSelect("Reenviar", 0, "e-mail de ativação"); } }, { children: "Reenviar e-mail de ativa\u00E7\u00E3o" })))] })) })) }))] })));
}
;
export default SubscriberActionsDropdown;
