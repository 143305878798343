var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Notification from "../../App/components/Notification";
import SubscriberService from "../services/SubscriberService";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { useCallback, useRef, useState, useEffect } from "react";
import { changedStatusId, checkoutStatusId, reactivatedStatusId, waitingCancellation, waitingSuspension } from "../../App/helpers/TokenStatusHelper";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ClearIcon from "@mui/icons-material/Clear";
import SubscriberActionsDropdown from "./SubscriberActionsDropdown";
import Tooltip from "@mui/material/Tooltip";
import SubscribersList from "./SubscribersList";
import { Pagination } from "@mui/material";
import ModalReportSubscribers from "./ModalReportSubscribers";
import ConfirmAction from "./ConfirmAction";
import useContentSuppliers from "../../App/hooks/useContentSuppliers";
var subsArrayDocumentForMassiveAction = [];
export function GloboList(props) {
    var _this = this;
    var _a = useForm(), register = _a.register, handleSubmit = _a.handleSubmit;
    var _b = useSearchParams(), searchParams = _b[0], setSearchParams = _b[1];
    var subscriberIdentification = searchParams.get('document') !== null ? searchParams.get('document') : "";
    var _c = useState([]), subscribersList = _c[0], setSubscribersList = _c[1];
    var _d = useState(0), qtyError = _d[0], setQtyError = _d[1];
    var _e = useState(subscriberIdentification), searchingTerm = _e[0], setSearchingTerm = _e[1];
    var _f = useState(false), showingErrors = _f[0], setShowingErrors = _f[1];
    var _g = useState(false), showingSelected = _g[0], setShowingSelected = _g[1];
    var _h = useState(0), qtyChecked = _h[0], setQtyChecked = _h[1];
    var _j = useState([]), isChecked = _j[0], setIsChecked = _j[1];
    var _k = useState(0), serviceValue = _k[0], setServiceValue = _k[1];
    var _l = useState([]), serviceStatus = _l[0], setServiceStatus = _l[1];
    var _m = useState(false), showServicesProvision = _m[0], setShowServicesProvision = _m[1];
    var _o = useState(false), showServicesSuspend = _o[0], setShowServicesSuspend = _o[1];
    var _p = useState(false), cleared = _p[0], setCleared = _p[1];
    var _q = useState(false), modalShow = _q[0], setModalShow = _q[1];
    var _r = useState(null), modalBulkActionInfo = _r[0], setModalBulkActionInfo = _r[1];
    var _s = useState(0), reportId = _s[0], setReportId = _s[1];
    var _t = useState(""), subscriberError = _t[0], setSubscriberError = _t[1];
    var _u = useState([]), selectedData = _u[0], setSelectedData = _u[1];
    var _v = useState(false), checkedAll = _v[0], setCheckedAll = _v[1];
    var _w = useState(false), showModalConfirmAction = _w[0], setShowModalConfirmAction = _w[1];
    var _x = useState(1), currentPage = _x[0], setCurrentPage = _x[1];
    var _y = useState(0), totalItems = _y[0], setTotalItems = _y[1];
    var _z = useState(20), perPage = _z[0], setPerPage = _z[1];
    var _0 = useState(null), paginateData = _0[0], setPaginateData = _0[1];
    var _1 = useState(''), selectedProductName = _1[0], setSelectedProductName = _1[1];
    var _2 = useState(''), selectedProduct = _2[0], setSelectedProduct = _2[1];
    var _3 = useState(''), selectedAction = _3[0], setSelectedAction = _3[1];
    var _4 = useState(null), activeRadio = _4[0], setActiveRadio = _4[1];
    var _5 = useState(null), activeSubscriber = _5[0], setActiveSubscriber = _5[1];
    var _6 = useState(false), isDropdownOpen = _6[0], setIsDropdownOpen = _6[1];
    var navbarRef = useRef(null);
    var _7 = useState(null), showProducts = _7[0], setShowProducts = _7[1];
    var hasFetchedRef = useRef(false);
    var _8 = useState(false), hasGloboFetched = _8[0], setHasGloboFetched = _8[1];
    var _9 = useContentSuppliers(), contentSuppliersProducts = _9.contentSuppliersProducts, getContentSuppliersProducts = _9.getContentSuppliersProducts;
    useEffect(function () {
        if (subscribersList.length === 0) {
            setCheckedAll(false);
        }
        else {
            var documents = subscribersList.map(function (item) { return item.document; });
            var containsAll = documents.every(function (item) { return selectedData.includes(item); });
            setCheckedAll(containsAll);
        }
    }, [subscribersList, selectedData]);
    useEffect(function () {
        if (!hasFetchedRef.current) {
            getContentSuppliersProducts();
            hasFetchedRef.current = true;
        }
    }, [getContentSuppliersProducts]);
    useEffect(function () {
        setQtyChecked(isChecked.length);
    }, [isChecked]);
    useEffect(function () {
        if (!hasGloboFetched) {
            getSubscribers(currentPage, perPage);
            getQtyError();
            setHasGloboFetched(true);
        }
    }, [props.selectedSupplierId, hasGloboFetched, props.contentSuppliers, subscribersList]);
    useEffect(function () {
        if (isChecked.length !== 0 && showingSelected) {
            getSubscribers(currentPage, perPage, isChecked);
        }
    }, [showingSelected, isChecked, currentPage, perPage]);
    useEffect(function () {
        handleCheckboxChange(selectedData);
    }, [selectedData]);
    useEffect(function () {
        if (subscriberIdentification !== null) {
            setSubscriberError(subscriberIdentification);
            setSearchingTerm(subscriberError);
            if (searchingTerm !== "") {
                handleSearch({ term: subscriberIdentification });
            }
        }
    }, [subscriberError]);
    useEffect(function () {
        if (showingErrors) {
            handleFilterError();
        }
    }, [showingErrors]);
    useEffect(function () {
        var handleClickDropdown = function (event) {
            if (navbarRef.current && !navbarRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickDropdown);
        return function () {
            document.removeEventListener('mousedown', handleClickDropdown);
        };
    }, []);
    var activeProducts = props.contentSuppliers.flatMap(function (supplier) { return supplier.content_supplier_products.filter(function (product) { return product.active === 1 && product.content_supplier_id === props.selectService; }); });
    function getSubscribers(page, perPage, selected) {
        if (selected === void 0) { selected = []; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Notification().Block.circle("[data-id=subscribers-grid]");
                        if (!(searchingTerm === "")) return [3 /*break*/, 1];
                        SubscriberService.all(perPage, selected, Number(serviceValue), Number(showingErrors), page)
                            .then(function (res) {
                            setSubscribersList(res.data.subscribers.data);
                            setTotalItems(res.data.subscribers.total);
                            var servicesArray = [];
                            res.data.subscribers.data.forEach(function (item) {
                                if (item.services && item.services.length > 0) {
                                    item.services.forEach(function (service) {
                                        servicesArray.push(service.status_id);
                                    });
                                }
                            });
                            setServiceStatus(servicesArray);
                            Notification().Block.remove("[data-id=subscribers-grid]");
                        })
                            .catch(function (err) {
                            if (err.response && err.response.status === 403) {
                                Notification().Notify.failure("Usuário não tem permissão para visualizar essa página!");
                            }
                            else {
                                Notification().Notify.failure("Erro ao buscar assinantes");
                            }
                        })
                            .finally(function () {
                            Notification().Block.remove("[data-id=subscribers-grid]");
                        });
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, handleSearch({ term: searchingTerm })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    ;
    function getQtyError() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Notification().Block.circle("[data-id=subscribers-grid]");
                        return [4 /*yield*/, SubscriberService.qtyError().then(function (res) {
                                setQtyError(res.data.errors);
                                Notification().Block.remove("[data-id=subscribers-grid]");
                            }, function (err) {
                                if (err.response.status === 403) {
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    ;
    function resendEmail(document) {
        Notification().Block.circle("[data-id=subscribers-grid]");
        return SubscriberService.resendEmail({ document: document }).then(function (res) {
            Notification().Notify.success("O e-mail foi reenviado com sucesso!");
            Notification().Block.remove("[data-id=subscribers-grid]");
        }, function (err) {
            Notification().Notify.failure("Erro ao reenviar o e-mail.");
            Notification().Block.remove("[data-id=subscribers-grid]");
        });
    }
    ;
    function hasServicesForResendEmail(subscriber) {
        var _a, _b;
        return (_b = (_a = subscriber.services) === null || _a === void 0 ? void 0 : _a.some(function (service) { return service.status_id === 5 || service.status_id === 1; })) !== null && _b !== void 0 ? _b : false;
    }
    ;
    function manageService(action, contentSupplierProductId, document) {
        return __awaiter(this, void 0, void 0, function () {
            var request, service, res, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Notification().Loading.dots("Carregando");
                        request = {
                            contentSupplierProduct: contentSupplierProductId,
                            documents: subsArrayDocumentForMassiveAction,
                        };
                        service = action === 'provision'
                            ? SubscriberService.provision
                            : action === 'desprovision'
                                ? SubscriberService.desprovision
                                : action === 'suspend'
                                    ? SubscriberService.suspend
                                    : null;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        if (!(action === 'resend_email' && document)) return [3 /*break*/, 3];
                        return [4 /*yield*/, resendEmail(document)];
                    case 2:
                        _a.sent();
                        Notification().Loading.remove();
                        return [2 /*return*/];
                    case 3:
                        if (!service) return [3 /*break*/, 5];
                        return [4 /*yield*/, service(request)];
                    case 4:
                        res = _a.sent();
                        Notification().Loading.remove();
                        Notification().Notify.success(res.data.response.success);
                        getSubscribers(currentPage, perPage);
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_1 = _a.sent();
                        Notification().Loading.remove();
                        handleErrorResponse(err_1);
                        return [3 /*break*/, 7];
                    case 7:
                        if (action === 'suspend') {
                            setShowServicesSuspend(!showServicesSuspend);
                        }
                        else {
                            setShowServicesProvision(!showServicesProvision);
                        }
                        clear();
                        return [2 /*return*/];
                }
            });
        });
    }
    ;
    function handleErrorResponse(err) {
        setSelectedAction('');
        setSelectedProduct('');
        setSelectedProductName('');
        if (err.response.status === 422) {
            setModalBulkActionInfo(err.response.data.response.error);
            var report_id = err.response.data.response.customer_report_id;
            if (report_id !== undefined) {
                setReportId(report_id);
            }
            getSubscribers(currentPage, perPage);
            setModalShow(true);
        }
        else if (err.response.status === 409) {
            Notification().Notify.info(err.response.data.response.error);
        }
        else if (err.response.status === 403) {
            Notification().Notify.failure("Usuário não tem permissão para executar essa ação!");
        }
        else {
            Notification().Notify.failure(err.message);
        }
    }
    ;
    function handleChange(_document, action) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!action.id) return [3 /*break*/, 5];
                        if (!(action.provisioner && action.provisionCallback)) return [3 /*break*/, 2];
                        return [4 /*yield*/, action.provisionCallback()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 2:
                        if (!(!action.provisioner && action.suspendCallback)) return [3 /*break*/, 4];
                        return [4 /*yield*/, action.suspendCallback()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        if (action.confirmationCallback) {
                            action.confirmationCallback();
                        }
                        else {
                            Notification().Notify.info("Está ação não está disponível!");
                        }
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    }
    ;
    function showServiceConfirmation(action, contentSupplierProductId) {
        var actionMessages = {
            provision: "Nenhum assinante selecionado para ativação.",
            suspend: "Nenhum assinante selecionado para suspensão.",
            desprovision: "Nenhum assinante selecionado para cancelamento.",
            resend_email: "Nenhum assinante selecionado para reenviar e-mail de ativação.",
        };
        if (isChecked.length === 0) {
            Notification().Notify.info(actionMessages[action]);
        }
        else {
            if (action === 'resend_email') {
                var document_1 = isChecked[0];
                manageService(action, contentSupplierProductId, document_1);
            }
            else {
                manageService(action, contentSupplierProductId);
            }
        }
    }
    ;
    var handleAction = function () {
        var productId = typeof selectedProduct === 'number' ? selectedProduct : parseInt(selectedProduct, 10);
        switch (selectedAction) {
            case 'Ativar':
                showServiceConfirmation('provision', productId);
                break;
            case 'Reativar':
                showServiceConfirmation('provision', productId);
                break;
            case 'Suspender':
                showServiceConfirmation('suspend', productId);
                break;
            case 'Cancelar':
                showServiceConfirmation('desprovision', productId);
                break;
            case 'Reenviar':
                showServiceConfirmation('resend_email', productId);
                break;
            default:
                break;
        }
        setSelectedAction('');
        setSelectedProduct('');
        setSelectedProductName('');
    };
    var handleFilterText = function (event) {
        setSearchingTerm(event.target.value);
    };
    function fixErrorAction(document, product_id, status_id) {
        var message, title = '';
        switch (status_id) {
            case checkoutStatusId:
                message = "Tem certeza que deseja tentar ativar o serviço novamente?";
                title = "Confirmação de ativação";
                break;
            case reactivatedStatusId:
                message = "Tem certeza que deseja tentar reativar o serviço novamente?";
                title = "Confirmação de reativação";
                break;
            case changedStatusId:
                message = "Tem certeza que deseja tentar alterar o serviço novamente?";
                title = "Confirmação de troca de produto";
                break;
            case waitingSuspension:
                message = "Tem certeza que deseja tentar suspender o serviço novamente?";
                title = "Confirmação de suspensão";
                break;
            case waitingCancellation:
                message = "Tem certeza que deseja tentar cancelar o serviço novamente?";
                title = "Confirmação de cancelamento";
                break;
            default:
                message = "Deseja refazer a ação?";
                title = "Confirmação da ação";
                break;
        }
        Notification().Confirm.show(title, message, "Sim", "Não", function () {
            Notification().Block.circle("[data-id=subscribers-grid]");
            SubscriberService.fixError({
                subscriber_document: document,
                content_supplier_product_id: product_id,
                service_status_id: status_id
            }).then(function (res) {
                Notification().Block.remove("[data-id=subscribers-grid]");
                Notification().Notify.success(res.data.response.success);
                getSubscribers(currentPage, perPage);
                getQtyError();
            }, function (err) {
                Notification().Block.remove("[data-id=subscribers-grid]");
                if (err.response.status === 422) {
                    Notification().Notify.failure(err.response.data.response.error);
                }
                else if (err.response.status === 403) {
                    Notification().Notify.failure("Usuário não tem permissão para executar essa ação!");
                }
                else {
                    Notification().Notify.failure("Erro ao tentar corrigir o erro. Entre em contato com o suporte");
                }
            });
        });
    }
    ;
    function handleFilterAction(event) {
        Notification().Block.circle("[data-id=subscribers-grid]");
        searchingTerm === "" ?
            SubscriberService.all(perPage, isChecked, Number(event.target.value), Number(showingErrors)).then(function (res) {
                setServiceValue(Number(event.target.value));
                setSubscribersList(res.data.subscribers.data);
                setPaginateData(res.data.subscribers);
                Notification().Block.remove("[data-id=subscribers-grid]");
            }, function (err) {
                if (err.response.status === 403) {
                    Notification().Notify.failure("Usuário não tem permissão para visualizar essa página!");
                }
                else {
                    Notification().Notify.failure(err.message);
                }
                Notification().Block.remove("[data-id=subscribers-grid]");
            }) :
            SubscriberService.search(searchingTerm, perPage, isChecked, Number(event.target.value), Number(showingErrors)).then(function (res) {
                setServiceValue(Number(event.target.value));
                setSearchingTerm(searchingTerm);
                setSubscribersList(res.data.subscribers.data);
                setPaginateData(res.data.subscribers);
                Notification().Block.remove("[data-id=subscribers-grid]");
            }, function (err) {
                Notification().Block.remove("[data-id=subscribers-grid]");
                if (err.response.status === 403) {
                    Notification().Notify.failure("Usuário não tem permissão para executar essa ação!");
                }
                else {
                    Notification().Notify.failure(err.message);
                }
            });
    }
    ;
    function handleShowingErrors() {
        setShowingErrors(!showingErrors);
    }
    ;
    function handleFilterError() {
        Notification().Block.circle("[data-id=subscribers-grid]");
        searchingTerm === "" ?
            SubscriberService.all(perPage, isChecked, serviceValue, Number(showingErrors)).then(function (res) {
                setSubscribersList(res.data.subscribers.data);
                setPaginateData(res.data.subscribers);
                Notification().Block.remove("[data-id=subscribers-grid]");
            }, function (err) {
                if (err.response.status === 403) {
                    Notification().Notify.failure("Usuário não tem permissão para visualizar essa página!");
                }
                else {
                    Notification().Notify.failure(err.message);
                }
            }) : SubscriberService.search(searchingTerm, perPage, isChecked, serviceValue, Number(showingErrors)).then(function (res) {
            setSubscribersList(res.data.subscribers.data);
            setPaginateData(res.data.subscribers);
            Notification().Block.remove("[data-id=subscribers-grid]");
        }, function (err) {
            Notification().Block.remove("[data-id=subscribers-grid]");
            if (err.response.status === 403) {
                Notification().Notify.failure("Usuário não tem permissão para executar essa ação!");
            }
            else {
                Notification().Notify.failure(err.message);
            }
        });
    }
    ;
    var handleCheckboxChange = useCallback(function (selected_data) {
        subsArrayDocumentForMassiveAction = selected_data;
        setCleared(false);
        setIsChecked(selected_data);
    }, []);
    function handleCheckbox(document) {
        if (activeRadio === document) {
            setActiveRadio(null);
            setSelectedData([]);
            setActiveSubscriber(null);
        }
        else {
            setSelectedData([document]);
            setActiveRadio(document);
            var selectedSubscriber = subscribersList.find(function (subscriber) { return subscriber.document === document; });
            setActiveSubscriber(selectedSubscriber || null);
        }
    }
    ;
    function handleSearch(subscribersSearch) {
        return __awaiter(this, void 0, void 0, function () {
            var selected;
            return __generator(this, function (_a) {
                selected = [];
                if (showingSelected) {
                    selected = __spreadArray([], isChecked, true);
                }
                Notification().Block.circle("[data-id=subscribers-grid]");
                SubscriberService.search(searchingTerm, perPage, selected, serviceValue, Number(showingErrors)).then(function (res) {
                    setSearchingTerm(searchingTerm);
                    setSubscribersList(res.data.subscribers.data);
                    setPaginateData(res.data.subscribers);
                    Notification().Block.remove("[data-id=subscribers-grid]");
                }, function (err) {
                    Notification().Block.remove("[data-id=subscribers-grid]");
                    if (err.response.status === 403) {
                        Notification().Notify.failure("Usuário não tem permissão para executar essa ação!");
                    }
                    else {
                        Notification().Notify.failure(err.message);
                    }
                });
                return [2 /*return*/];
            });
        });
    }
    ;
    function cleanFilter() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                Notification().Block.circle("[data-id=subscribers-grid]");
                SubscriberService.all(perPage)
                    .then(function (subscribersResponse) {
                    setSubscribersList(subscribersResponse.data.subscribers.data);
                    setSearchingTerm("");
                    setServiceValue(0);
                    setShowingErrors(false);
                    setSearchParams("");
                    setSubscriberError("");
                    setPaginateData(subscribersResponse.data.subscribers);
                    setActiveRadio(null);
                    setSelectedData([]);
                    setSelectedAction('');
                    setSelectedProduct('');
                    setSelectedProductName('');
                })
                    .catch(function (err) {
                    if (err.response && err.response.status === 403) {
                        Notification().Notify.failure("Usuário não tem permissão para visualizar essa página!");
                    }
                    else {
                        Notification().Notify.failure(err.message);
                    }
                })
                    .finally(function () {
                    Notification().Block.remove("[data-id=subscribers-grid]");
                });
                return [2 /*return*/];
            });
        });
    }
    ;
    function clear() {
        setCleared(true);
        getSubscribers(currentPage, perPage);
    }
    ;
    var handlePageChange = function (event, value) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (value > Math.ceil(totalItems / perPage)) {
                return [2 /*return*/];
            }
            Notification().Block.circle("[data-id=subscribers-grid]");
            setCurrentPage(value);
            getSubscribers(value, perPage).finally(function () {
                Notification().Block.remove("[data-id=subscribers-grid]");
            });
            return [2 /*return*/];
        });
    }); };
    return (_jsxs("div", __assign({ "data-id": "subscribers-grid" }, { children: [_jsxs("div", __assign({ className: "action-container-for-subscriber-management" }, { children: [_jsx("div", __assign({ className: "search-subscribers" }, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(handleSearch) }, { children: [_jsx("button", __assign({ type: "submit" }, { children: _jsx(SearchRoundedIcon, {}) })), _jsx("input", __assign({}, register("term"), { required: true, type: "text", placeholder: "Busca por assinante", "aria-label": "Busca por assinante", "aria-describedby": "button-addon2", onChange: handleFilterText, value: searchingTerm })), searchingTerm && (_jsx("button", __assign({ type: "button", onClick: cleanFilter }, { children: _jsx(ClearIcon, {}) })))] })) })), _jsxs("div", __assign({ className: "container-dropdown-subscribers" }, { children: [_jsx(SubscriberActionsDropdown, { isDropdownOpen: isDropdownOpen, setIsDropdownOpen: setIsDropdownOpen, selectedAction: selectedAction, selectedProductName: selectedProductName, setSelectedAction: setSelectedAction, setSelectedProduct: setSelectedProduct, setShowProducts: setShowProducts, showProducts: showProducts, contentSuppliers: props.contentSuppliers, selectService: props.selectService, activeProducts: activeProducts, activeSubscriber: activeSubscriber, hasServicesForResendEmail: hasServicesForResendEmail, navbarRef: navbarRef, setSelectedProductName: setSelectedProductName }), _jsx("div", __assign({ className: "d-flex align-items-center justify-content-center" }, { children: !(qtyChecked !== 0 && selectedAction !== '') ? (_jsx(Tooltip, __assign({ title: "Selecione um assinante", placement: "top", arrow: true }, { children: _jsx("span", { children: _jsx("button", __assign({ className: "button-action-confirm", onClick: function () {
                                                if (qtyChecked !== 0 && selectedAction !== '') {
                                                    setShowModalConfirmAction(true);
                                                }
                                            }, style: { backgroundColor: "gray" }, disabled: true }, { children: "Confirmar" })) }) }))) : (_jsx("button", __assign({ className: "button-action-confirm", onClick: function () {
                                        if (qtyChecked !== 0 && selectedAction !== '') {
                                            setShowModalConfirmAction(true);
                                        }
                                    }, style: { backgroundColor: "" }, disabled: false }, { children: "Confirmar" }))) }))] }))] })), _jsx(SubscribersList, { contentSupplierProductsList: contentSuppliersProducts, subscribersList: subscribersList, searchingTerm: searchingTerm, qtyErrors: qtyError, callbackCheckbox: handleCheckboxChange, callbackActions: handleChange, callbackFilterService: handleFilterAction, serviceValue: serviceValue, callbackFilterClear: cleanFilter, callbackFixErrorAction: fixErrorAction, callbackFilterError: handleShowingErrors, clearSelected: cleared, callbackCheckboxSubscriber: handleCheckbox, selectedData: selectedData, contentSupplierId: props.selectService, contentSuppliers: props.contentSuppliers, activeRadio: activeRadio }), _jsx("div", __assign({ style: { display: "flex", justifyContent: "flex-end", paddingTop: "1rem" } }, { children: _jsx(Pagination, { count: Math.ceil(totalItems / perPage), page: currentPage, onChange: handlePageChange, color: "standard", sx: {
                        '& .MuiPaginationItem-root': {
                            color: '#ff8926',
                        },
                        '& .MuiPaginationItem-root.Mui-selected': {
                            backgroundColor: '#ff8926',
                            color: '#fff',
                        },
                        '& .MuiPaginationItem-root:hover': {
                            backgroundColor: '#ff8926',
                            color: '#fff',
                        }
                    }, showFirstButton: true, showLastButton: true }) })), _jsx(ConfirmAction, { show: showModalConfirmAction, closeModal: function () { return setShowModalConfirmAction(false); }, actionType: selectedAction.toLowerCase(), onConfirm: handleAction }), modalShow &&
                _jsx(ModalReportSubscribers, { show: modalShow, bulkActionInfo: modalBulkActionInfo, reportId: reportId, closeModal: function () { setModalShow(false); } })] })));
}
export default GloboList;
