var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import "../styles/ContentSuppliersTabs.css";
var ContentSuppliersTabs = function (_a) {
    var contentSuppliers = _a.contentSuppliers, onSupplierChange = _a.onSupplierChange, selectedSupplierId = _a.selectedSupplierId;
    return (_jsx("div", __assign({ className: "tab-container-content-suppliers" }, { children: _jsx("ul", __assign({ className: "tab-list" }, { children: contentSuppliers.map(function (supplier) { return (_jsx("li", __assign({ className: "tab-item ".concat(selectedSupplierId === supplier.id ? 'active' : ''), onClick: function () { return onSupplierChange(supplier.id); } }, { children: supplier.name }), supplier.id)); }) })) })));
};
export default ContentSuppliersTabs;
