var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { api } from "../../App/services/api";
function all(perPage, selected, service, hasError, page) {
    if (selected === void 0) { selected = []; }
    if (service === void 0) { service = 0; }
    if (hasError === void 0) { hasError = 0; }
    if (page === void 0) { page = 1; }
    var uri = "/subscribers?per_page=".concat(perPage, "&page=").concat(page);
    uri = selected.length === 0 ? uri : uri + "&selected=".concat(selected.toString());
    uri = service === 0 ? uri : uri + "&service=".concat(service);
    uri = hasError === 0 ? uri : uri + "&has_error=".concat(hasError);
    return api.get(uri);
}
function paginate(url) {
    return api.get(url);
}
function find(document) {
    return api.get("/subscribers/".concat(document));
}
function store(newSubscriberData) {
    return api.post("/subscribers", newSubscriberData);
}
//TODO: Ajustar a tipagem do subscribersData
function storeMany(subscribersData) {
    return api.post('/subscribers/import/csv', subscribersData);
}
function exportMany() {
    return api.get('/subscribers/export');
}
function update(document, updateSubscriberData) {
    return api.put("/subscribers/".concat(document), updateSubscriberData);
}
function destroy(document) {
    return api.delete("/subscribers/".concat(document));
}
function destroyMany(subscribers) {
    return api.post('/subscribers/destroy_many', {
        documents: subscribers
    });
}
function provision(request) {
    return api.post("/provision/activate", request);
}
function desprovision(request) {
    return api.post("/provision/deactivate", request);
}
function reactivate(request) {
    return api.post("/provision/change", request);
}
function suspend(request) {
    return api.post("/provision/suspend", request);
}
function change(contentSupplierProduct, newContentSupplierProduct, document) {
    return api.post('/provision/change', {
        previous_product_id: contentSupplierProduct,
        product_id: newContentSupplierProduct,
        document: document
    });
}
function search(subscriberSearch, perPage, selected, service, hasError) {
    if (selected === void 0) { selected = []; }
    if (service === void 0) { service = 0; }
    if (hasError === void 0) { hasError = 0; }
    var uri = "/subscribers/search/".concat(subscriberSearch, "?per_page=").concat(perPage);
    uri = selected.length === 0 ?
        uri :
        uri + "&selected=".concat(selected.toString());
    uri = service === 0 ?
        uri :
        uri + "&service=".concat(service);
    uri = hasError === 0 ?
        uri :
        uri + "&has_error=".concat(hasError);
    return api.get(uri);
}
function fixError(payloadData) {
    return api.post('/provision/fix_error', payloadData);
}
function qtyError() {
    return api.get("/subscribers/qty_error");
}
// TODO: Adicionar método que retorna os ContentSuppliers de um Subscriber.
function getSubscriberContentSupply(subscriberId) {
    return api.get("/subscribers/content_suppliers/".concat(subscriberId));
}
function getSubscriberSpeed() {
    return api.get("/subscribers/internet_speeds");
}
function getSubscriberHistory(subscriberId, serviceId) {
    var url = serviceId
        ? "/subscribers/history/".concat(subscriberId, "/").concat(serviceId)
        : "/subscribers/history/".concat(subscriberId);
    return api.get(url);
}
function resendEmail(payload) {
    return api.post('/subscribers/resend_activation_email', payload);
}
function getAllDocumentsByCustomerId(page, perPage, orderBy) {
    if (page === void 0) { page = 1; }
    if (perPage === void 0) { perPage = 20; }
    if (orderBy === void 0) { orderBy = []; }
    var uri = "/meliuz?page=".concat(page, "&per_page=").concat(perPage);
    if (orderBy.length > 0) {
        uri += "&order_by=".concat(orderBy.join(','));
    }
    return api.get(uri);
}
function searchDocumentsByCustomerId(params) {
    if (params === void 0) { params = {}; }
    return __awaiter(this, void 0, void 0, function () {
        var uri, searchValue;
        return __generator(this, function (_a) {
            uri = "/meliuz/search";
            searchValue = params.search_value || '';
            if (searchValue) {
                uri += "?search_value=".concat(encodeURIComponent(searchValue));
            }
            if (params.limit) {
                uri += "&limit=".concat(params.limit);
            }
            if (params.order_by && params.order_by.length > 0) {
                uri += "&order_by=".concat(params.order_by.join(','));
            }
            return [2 /*return*/, api.get(uri)];
        });
    });
}
function storeCSVMeliuz(subscribersData) {
    return api.post('/meliuz/import/csv', subscribersData);
}
export default {
    all: all,
    paginate: paginate,
    find: find,
    store: store,
    reactivate: reactivate,
    storeMany: storeMany,
    update: update,
    destroy: destroy,
    destroyMany: destroyMany,
    provision: provision,
    desprovision: desprovision,
    change: change,
    suspend: suspend,
    search: search,
    fixError: fixError,
    qtyError: qtyError,
    getSubscriberSpeed: getSubscriberSpeed,
    getSubscriberHistory: getSubscriberHistory,
    resendEmail: resendEmail,
    exportMany: exportMany,
    getAllDocumentsByCustomerId: getAllDocumentsByCustomerId,
    searchDocumentsByCustomerId: searchDocumentsByCustomerId,
    storeCSVMeliuz: storeCSVMeliuz
};
